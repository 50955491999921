import '../styles/index.scss'
import '@staaky/tipped/dist/css/tipped.css'
require('../images/logo.png')

window.$ = window.jQuery = require('jquery')

var Tipped = require('@staaky/tipped')

function application () {
  Tipped.create('#ArsRatio', 'Demnächst mehr!')
}

function ready (fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

ready(application)
